body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.grid-padding {
    padding: 15px 16px;
    border: solid 1px #f5f5f5;
    min-height: 150px;
    margin: 0 8px;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
    border-radius: 0 0 4px 4px;
}

footer.hKAfYq iframe {
    margin-top: 5px;
}

.iubenda-cs-preferences-link {
    background: #FFF none no-repeat;
    padding-left: 20px;
    text-decoration: none;
    color: #59636D;
    -webkit-box-shadow: 0 1px 0 #ebebec;
    box-shadow: 0 1px 0 #ebebec;
    -webkit-box-shadow: 0 1px 0 rgb(0 0 0 / 8%);
    box-shadow: 0 1px 0 rgb(0 0 0 / 8%);
    font-weight: bolder;
    font-size: 0.75rem;
    padding: 1px 6px;
    border-radius: 2px;
    border: 1px solid #D2D3D3;
    display: inline-block;
}

td.flex {
    display: flex;
    justify-content: space-between;
}

.yousign-dialog .MuiDialog-container .MuiDialog-paperScrollPaper {
    padding-bottom: 6%;
}

.btn-close {
    background: #f44336;
    color: #fff;
    padding: 10px 25px !important;
}

.simple-link, .simple-link a {
    text-align: center;
    color: #dd3333;
    font-weight: bold;
    padding: 30px 0 0;
}

.text-bordered {
    border: solid 5px #f3b117;
    padding: 1% 2%;
    background: #fef6ad17;
}

.edit-practice-step1-box {
    background: #f3b117;
    border: solid 3px #20aaea;
    padding: 3% !important;
}

.edit-practice-step2-box {
    margin-top: 3%;
    background: #fff;
    padding: 3% !important;
}

.edit-practice-step2-spacer {
    padding: 3% !important;
}


/*Edit Practice Sidebar*/
.form-sidebar {
    margin-left: 5px;
    padding: 5px;
    background: #0172e4;
    background: linear-gradient(to right, #0172e4, #0059B3);
}

.aside-panel {
    /*border: 1px solid #cce1ff;*/
    border-radius: 3px;
    margin-bottom: 20px;
    width: 99%;
}

.aside-panel-deposit {
    border-radius: 3px;
    margin-bottom: 20px;
    width: 99%;
}

.aside-panel-deposit-mobile {
    border-radius: 3px;
    margin-bottom: 16px;
    display: none;
    margin-top: 16px;
}

.aside-title {
    background-color: #e9f0fa7a;
    border-bottom: 3px solid #cce1ff;
}

.aside-title-deposit {
    background-color: #3d8adb;
    border: 3px solid #ffc41d;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    border-radius: 10px;
}

.aside-title span {
    font-size: 16px;
    font-weight: bold;
}

.aside-content ul {
    padding-left: 15px;
}

.aside-content ul li {
    font-size: 15px;
}

.highlight-color {
    background: #ffc41a !important;
}

.aside-panel ul li {
    color: #fff;
    list-style-type: disc;
    line-height: 1.4;
    font-weight: 500;
    letter-spacing: 0;
}

.aside-content {
    display: block !important;
    margin: 0 !important;
    padding: 0 !important;
}

.aside-panel .aside-content ul {
    display: block;
    width: 100%;
    margin: 0;
    padding: 0 !important;
}

.aside-panel .aside-content ul li {
    list-style: none;
    padding: 3px;
}

.aside-content .pak-optioned * {
    color: #012089;
    font-weight: 600;
}

.aside-content .pak-optioned li,
.aside-content .pak-preventivo li {
    list-style: none;
}

.content-summary .aside-booking {
    width: 70%;
    margin: 15px auto;
}

.aside-list {
    overflow-y: scroll !important;
    max-height: 90vh !important;
}

.price-specs {
    margin: 10px 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: max-content;
    align-items: center;
    grid-gap: 10px;
}
.price-specs small {
    grid-column: 1/ span 2;
}

.price-si dt{
    width: 70%;
}
.price-si dt, .price-si dd{
    display: block;
    float: left;
    color: #fff;
    line-height: 1.47;
    font-size: 0.9rem;
}
.price-si dd{
    float: right;
    font-weight: bold;
    text-align: right;
    padding: 0 5px;
}
.price-si dd:after{
    content:"";
    width: 100%;
    display: block;
    clear: both;
}
.price-si .text-highlights{
    background: #ffc41a;
    color: #111;
    padding: 2px;
    border-radius: 5px;
}
.price-si dd.dl-full, .price-si dt.dl-full {
    float: none;
    clear: both;
    margin: 0px!important;
}
/* A77 style */

.btn-outline{
    display: inline;
    padding: 5px 10px;
    border-radius: 4px;
    margin: 0 10px;
    text-transform: uppercase;
    font-weight: 500;
}
.btn-edit-services{
    border: 1px solid rgba(243, 177, 23, 0.5);
    color: #f3b117;
}
.btn-undo-services{
    border: 1px solid rgba(245, 0, 87, 0.5);
    color: rgb(245, 0, 87);
}
.btn-save-services{
    border: 1px solid rgba(0, 128, 0, 0.5);
    color: rgb(0, 128, 0);
}
/* next-appennino */
img.alignnone.image-next{
    width: 70%;
    height: auto;
}

.w-60, .w-40{
    float: left;
}
.w-60{
    width: 60%;
}
.w-40{
    width: 40%;
}
#next-app{
    max-width: 1024px;
    margin: 0 auto !important;
}
.shortCode{
    background: yellow;
    font-size: 1.2rem;
}

#club-app{
    max-width: 1024px;
    margin: 0 auto !important;
    background: rgb(40,40,40);
    background: -moz-linear-gradient(148deg, rgba(40,40,40,1) 30%, rgba(172,172,172,1) 90%);
    background: -webkit-linear-gradient(148deg, rgba(40,40,40,1) 30%, rgba(172,172,172,1) 90%);
    background: linear-gradient(148deg, rgba(40,40,40,1) 30%, rgba(172,172,172,1) 90%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#282828",endColorstr="#acacac",GradientType=1);
    /*padding: 2%;*/
    border-radius: 10px;
}
#club-app div:first-child{
    /*background: rgb(0,0,0); 
    background: linear-gradient(148deg, rgba(0,0,0,1) 0%, rgba(91,91,91,1) 71%);
    padding: 2%;
    border-radius: 5px;*/
    color: #fff;
    clear: both;
    overflow: auto;
}
#club-app .w-40{
    text-align: center;
}
#club-app a{
    max-width: 45%;
    display: inline-block;
    margin: 0;
}
#club-app img{
    /*margin: 4%;
    box-shadow: 0 0 3px #fff;*/
    border-radius: 5px;
    width: 100%;
}

/* Responsive Style */
@media all and (max-width: 763px){ 
    /* smartphones, iPhone, portrait 480x320 phones */ 

    .MuiExpansionPanelDetails-root{
        padding: 8px!important;
    }
    
    .responsivecolumn {
        flex-direction: column;
    }

    .responsivecolumn div {
        margin-left: inherit;
    }

    .responsivecomumn .MuiGrid-grid-xs-12{
        margin-top: 12px;
        padding-left:0;
        border-left: none;
    }
    /* table voucher */

    .tb_voucher .MuiTableCell-root {
        display: block;
        padding: 6px 16px;
    }
    .tb_voucher thead.MuiTableHead-root {
        display: none;
    }
    .tb_voucher tr.MuiTableRow-root{
        border: solid 2px #ccc;
    }
    .tb_voucher tr.MuiTableRow-root td.MuiTableCell-root:nth-child(1):before {
        content: "Codice Voucher: ";
        display: inline;
        font-weight: bold;
    }
    .tb_voucher tr.MuiTableRow-root td.MuiTableCell-root:nth-child(2):before {
        content: "Valore Voucher: ";
        display: inline;
        font-weight: bold;
    }
    .tb_voucher tr.MuiTableRow-root td.MuiTableCell-root:nth-child(3):before {
        content: "Valore Consumato: ";
        display: inline;
        font-weight: bold;
    }
    .tb_voucher tr.MuiTableRow-root td.MuiTableCell-root:nth-child(4):before {
        content: "Sconto Integrativo: ";
        display: inline;
        font-weight: bold;
    }
    .tb_voucher tr.MuiTableRow-root td.MuiTableCell-root:nth-child(5):before {
        content: "Sconto Consumato: ";
        display: inline;
        font-weight: bold;
    }
    .tb_voucher tr.MuiTableRow-root td.MuiTableCell-root:nth-child(6):before {
        content: "Dettaglio: ";
        display: inline;
        font-weight: bold;
    }
    .tb_voucher tr.MuiTableRow-root td.MuiTableCell-root:nth-child(7):before {
        content: "Valore Totale: ";
        display: inline;
        font-weight: bold;
    }
    .tb_voucher tr.MuiTableRow-root td.MuiTableCell-root:nth-child(8):before {
        content: "Scadenza: ";
        display: inline;
        font-weight: bold;
    }
    .tb_voucher tr.MuiTableRow-root td.MuiTableCell-root:nth-child(9):before {
        content: "Riscattato: ";
        display: inline;
        font-weight: bold;
    }
    .tb_voucher tr.MuiTableRow-root td.MuiTableCell-root:nth-child(10):before {
        content: "Divisione: ";
        display: inline;
        font-weight: bold;
    }
    .tb_voucher tr.MuiTableRow-root:nth-child(even) {
        background: #eee;
    }

    /* tabella Documenti */

    .tb_documents .MuiTableCell-root {
        display: block;
        padding: 6px 16px;
    }
    .tb_documents thead.MuiTableHead-root {
        display: none;
    }
    .tb_documents tr.MuiTableRow-root{
        border: solid 2px #ccc;
    }
    .tb_documents tr.MuiTableRow-root td.MuiTableCell-root:nth-child(1):before {
        content: "Data: ";
        display: block;
        font-weight: bold;
    }
    .tb_documents tr.MuiTableRow-root td.MuiTableCell-root:nth-child(2):before {
        content: "Tipologia: ";
        display: block;
        font-weight: bold;
    }
    .tb_documents tr.MuiTableRow-root td.MuiTableCell-root:nth-child(3):before {
        content: "Nome Documento: ";
        display: block;
        font-weight: bold;
    }
    .tb_documents tr.MuiTableRow-root td.MuiTableCell-root:nth-child(4):before {
        content: "Generato/Caricato da: ";
        display: block;
        font-weight: bold;
    }
    .tb_documents tr.MuiTableRow-root td.MuiTableCell-root:nth-child(1):before {
        content: "Link file: ";
        display: block;
        font-weight: bold;
    }
    .tb_documents tr.MuiTableRow-root:nth-child(even) {
        background: #eee;
    }

    /* tablella invoices*/
    .tab_invoices .MuiTableCell-root {
        display: block;
        padding: 6px 16px;
    }
    .tab_invoices thead.MuiTableHead-root {
        display: none;
    }
    .tab_invoices tr.MuiTableRow-root{
        border: solid 2px #ccc;
    }
    .tab_invoices tr.MuiTableRow-root td.MuiTableCell-root:nth-child(1):before {
        content: "Doc n.: ";
        display: inline;
        font-weight: bold;
    }
    .tab_invoices tr.MuiTableRow-root td.MuiTableCell-root:nth-child(2):before {
        content: "Data: ";
        display: inline;
        font-weight: bold;
    }
    .tab_invoices tr.MuiTableRow-root td.MuiTableCell-root:nth-child(3):before {
        content: "Importo: ";
        display: inline;
        font-weight: bold;
    }
    .tab_invoices tr.MuiTableRow-root td.MuiTableCell-root:nth-child(4):before {
        content: "Documento: ";
        display: inline;
        font-weight: bold;
    }

    .tab_invoices td.MuiTableCell-alignCenter,
    .tb_documents td.MuiTableCell-alignCenter,
    .tb_voucher td.MuiTableCell-alignCenter  {
        text-align: left!important;
    }


    body .MuiPaper-elevation1 {
        box-shadow: inherit;
    }

    #next-app td {
        display: table-row;
    }
    .w-60, .w-40{
        float: inherit;
        clear: both;
    }
    /*#club-app{
        display: none;
    }*/
    #club-app .w-60, #club-app .w-40{
        width: 100%;
        padding: 1% 2%;
        text-align: center;
    }
}
